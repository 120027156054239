import {
  Box,
  Card,
  CardContent,
  Button,
  Grid,
  Typography,
  Container,
} from "@mui/material";
import React, { useEffect } from "react";
import { validateStepOne } from "../../../utils/Validation";
import { resetStepperData } from "../../../redux/slices/auth/signupSlice";
import TextFieldComponent from "../../TextFieldComponent";
import { walmartAccount } from "../../../redux/thunk/auth/authThunk";
import WalmartIcon from "../../WalmartIcon";
import HelpdeskIcon from "../../HelpdeskIcon";
import { useDispatch, useSelector } from "react-redux";

const Step1 = (props) => {
  const { stepperData, setStepperData, errors, setErrors, authType } = props;
  const { accStepper } = useSelector((state) => state.root.signup);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStepperData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { isValid, errors } = validateStepOne(stepperData);

    if (isValid) {
      let req = {
        apiData: stepperData,
        resetState: resetStepperData,
        auth_type: authType
      };
      const data = await dispatch(walmartAccount(req));
      if(data?.payload?.success){
        window.location.href = data?.payload?.redirect_url
      }
    }
    if (!isValid) {
      setErrors(errors);
    }
  };

  return (
    <Box>
      <Typography
        variant="headingXS"
        component="h3"
        align="center"
        sx={{ fontWeight: "bold" }}
      >
        Connect Your Walmart Store
      </Typography>
      <Container maxWidth="xs">
        <Typography variant="bodyXS" align="center" sx={{ mb: 5 }}>
          In order to perform audits we will need access to your Walmart Store.
          For a more detailed set of instructions
          <a href=""> click here.</a>
        </Typography>
      </Container>
      <Container maxWidth="sm">
        <Card>
          <CardContent>
            <TextFieldComponent
              id="name"
              name="name"
              label="Store name"
              value={stepperData?.name}
              onChange={handleInputChange}
              error={errors?.name}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
              sx={{ mt: 6 }}
              endIcon={<WalmartIcon />}
            >
              Connect to Walmart
            </Button>
          </CardContent>
        </Card>
      </Container>
      <HelpdeskIcon /> {/* Add HelpdeskIcon */}
    </Box>
  );
};

export default Step1;