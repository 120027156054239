import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { authApiWithToken } from "../../../utils/authApi";


export const verifyAccess = createAsyncThunk(
    'verification/verifyAccess',
    async (emailData, thunkAPI) => {
        try {
            const response = await authApiWithToken.post(
                "/email/email-verification/",
                emailData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        'x-skip-loading': 'true', // Add this to skip the loader
                    },
                }
            );
            return response.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
});


const initialState = {
    status: 'idle',
    error: null,
    isVerified: false,
}

const verificationSlice = createSlice({
    name: 'verification',
    initialState,

    extraReducers: (builder) => {
        builder.addCase(verifyAccess.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });
        builder.addCase(verifyAccess.fulfilled, (state, action) => {
            if (action.payload.verified) {
                state.status = 'success';
                state.isVerified = true;
            }
            else {
                state.status = 'failed';
                state.isVerified = false;
            }
        });
        builder.addCase(verifyAccess.rejected, (state, action) => {
            state.status = 'failed';
            state.isVerified = false;
            state.error = action.error.message;
        });
    }
});

export default verificationSlice.reducer;