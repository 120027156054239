import { combineReducers } from "@reduxjs/toolkit";
import signupReducer from "./redux/slices/auth/signupSlice";
import notificationReducer from "./redux/slices/notificationSlice";
import signinReducer from "./redux/slices/auth/signinSlice";
import dashboardSlice from "./redux/slices/dashboard/dashboardSlice";
import storedetailsSlice from "./redux/slices/storedetails/storedetailsSlice";
import dataLoadingSlice from "./redux/slices/dataLoadingSlice";
import addpaymentSlice from "./redux/slices/paymentmethod/addpaymentSlice";
import globalsearchSlice from "./redux/slices/globalsearchSlice";
import sidebarSlice from "./redux/slices/sidebarSlice";
import storeListSlice from "./redux/slices/storeListSlice";
import verificationSlice from "./redux/slices/auth/verificationSlice";

const appReducer = combineReducers({
  signup: signupReducer,
  login: signinReducer,
  loading: dataLoadingSlice,
  notification: notificationReducer,
  dashBoardState: dashboardSlice,
  storeState: storedetailsSlice,
  paymetState: addpaymentSlice,
  globalSearchState: globalsearchSlice,
  storeListState: storeListSlice,
  sidebatToggleState: sidebarSlice,
  verification: verificationSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
