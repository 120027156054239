import React, { useEffect, useRef, useState } from "react";
import StepperHandler from "../../../../components/StepperComponent/StepperHandler";
import { useDispatch } from "react-redux";
import { getTimeStampForStore } from "../../../../redux/thunk/auth/authThunk";
import { Paper } from "@mui/material";
import Step1 from "../../../../components/StepperComponent/Steps/Step1"
import Step2 from "../../../../components/StepperComponent/Steps/Step2"
import Step3 from "../../../../components/StepperComponent/Steps/Step3"
import HeaderNevigation from "../../../../components/HeaderNevigation/HeaderNevigation";

const CreateStore = () => {
  const [stepperData, setStepperData] = useState({
    contact_name: "",
    email: "",
    billing_contact_name: "",
    billing_email: "",
    name: "",
    walmart_client_id: "",
    walmart_client_secret: "",
  });
  const [usePrimaryEmail, setUsePrimaryEmail] = useState(false);
  const [useBillingPrimaryEmail, setUseBillingPrimaryEmail] = useState(false);
  const [errors, setErrors] = useState({});
  const buttonref = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTimeStampForStore());
  }, []);

  const headerData = [
    {
      linkName: "Setting",
      link: "/storedeatils",
      active: false,
    },
    {
      linkName: "Store Details",
      link: "/storedeatils",
      active: true,
    },
  ];

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            buttonref={buttonref}
            stepperData={stepperData}
            setStepperData={setStepperData}
            errors={errors}
            setErrors={setErrors}
            authType="3"
          />
        );
      case 1:
        return (
          <Step2
            buttonref={buttonref}
            stepperData={stepperData}
            setStepperData={setStepperData}
          />
        );
      case 2:
        return (
          <Step3
            buttonref={buttonref}
            stepperData={stepperData}
            setStepperData={setStepperData}
            usePrimaryEmail={usePrimaryEmail}
            useBillingPrimaryEmail={useBillingPrimaryEmail}
            setUsePrimaryEmail={setUsePrimaryEmail}
            setBillingPrimaryEmail={setUseBillingPrimaryEmail}
            errors={errors}
            setErrors={setErrors}
            authType="3"
          />
        );
      default:
        return "Unknown stepIndex";
    }
  };
  const steps = ["", "", ""];

  return (
    <>
      <HeaderNevigation headerData={headerData} />
      <Paper>
        <div className="wallmartAccount">
          <StepperHandler
            steps={steps}
            getStepContent={getStepContent}
            buttonref={buttonref}
          />
        </div>
      </Paper>
    </>
  );
};

export default CreateStore;
