import React from "react";
import { TextField, InputLabel, Tooltip, IconButton, } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";

function CustomInfoIcon(props) {
  return (
    <SvgIcon {...props}>
     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="45" fill="lightGray"/>
      <circle cx="50" cy="30" r="5" fill="black"/>
      <rect x="45" y="40" width="10" height="30" fill="black" rx="5" ry="5"/>
     </svg>
    </SvgIcon>
  );
}

const TextFieldComponent = ({
  id,
  name,
  type,
  value,
  onChange,
  error,
  label,
  infoText,
  ...props
}) => {
  return (
    <div className="formGroup">
      <InputLabel
        shrink
        htmlFor={id}
        // sx={{ textAlign: "left", fontWeight: 500 }}
        error={!!error}
        // className='font-tiny secondary-text-color'
      >
        {error ? (
          <span>{error}</span>
        ) : (
          <>
            {label}
            {/* <span style={{ color: "red" }}>*</span> */}
          </>
        )}
        {infoText && 
        <Tooltip title={infoText} placement="right-start">
          <IconButton size="small">
            <CustomInfoIcon sx={{ fontSize:"15px" }} />
          </IconButton>
        </Tooltip>
        }
      </InputLabel>
      <TextField
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        type={type || "text"}
        fullWidth
        {...props}
      />
    </div>
  );
};

export default TextFieldComponent;
