import React, { useState } from "react";
import Logo from "../../../assets/images/logo/logo.svg";
import GetStepper from "./GetStepper";

import {
  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
  Avatar,
} from "@mui/material";

import profileAvatar from "../../../assets/images/profileAvatar.jpeg";
import Logout from "@mui/icons-material/Logout";

import { setLogout } from "../../../redux/slices/auth/signinSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SuccessToast } from "../../../utils/Toaster";

const WalmartAccLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClose = () => {
    dispatch(setLogout());
    dispatch({ type: "RESET_STORE" });
    localStorage.removeItem("token");
    navigate("/login");
    setAnchorEl(null);
    SuccessToast("User logged out successfully");
    setIsMenuOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  return (
    <>
      <div
        className="minimalHeader"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <img
          src={Logo}
          alt="Logo"
          width={160}
          height={22}
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
        <div style={{ marginLeft: "auto" }}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ p: 0, ml: 0, width: 40, height: 29 }}
            aria-controls={isMenuOpen ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? "true" : undefined}
          >
            <Avatar alt="" variant="rounded" src={profileAvatar} />
          </IconButton>
          <Menu
            onClick={() => setIsMenuOpen(false)}
            anchorEl={anchorEl}
            id="account-menu"
            open={isMenuOpen}
            disableScrollLock
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>

      <div className="minimalBody">
        <GetStepper />
      </div>
    </>
  );
};

export default WalmartAccLogin;
