import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  Grid,
  Typography,
  Chip,
  Box,
  Divider,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addDefaultCardData,
  addStoreData,
  updateStoreData,
} from "../../../redux/thunk/storedetails/stroredetailsThunk";
import { listCardThunk } from "../../../redux/thunk/payment/addpaymetdetailsThunk";
import withHighlight from "../../../components/GlobalSearch/withHighlight";
import WalmartIcon from "../../../components/WalmartIcon";
import HeaderNevigation from "../../../components/HeaderNevigation/HeaderNevigation";
import { ChipCard } from "../../../components/ChipCard";
import { resetStepperData } from "../../../redux/slices/auth/signupSlice";
import { walmartAccount } from "../../../redux/thunk/auth/authThunk";
import { styled } from '@mui/system';
import { ErrorToast, SuccessToast } from "../../../utils/Toaster";

const ReconnectButton = styled(Button)({
  backgroundColor: '#EAF4FE',
  color: '#3367D6',
  textTransform: 'none',
  borderRadius: '12px',
  boxShadow: 'none',
  fontWeight: 'bold',
  fontSize: '16px',
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: '#D9EEFC', // Slightly darker on hover
    boxShadow: 'none',
  },
});

const CustomChip = ({ label }) => {
  const chipConfig = {
    Active: { color: "success", updatedLabel: "Active" },
    InActive: { color: "error", updatedLabel: "InActive" },
  };

  const { color, updatedLabel } = chipConfig[label] || {};

  return <Chip label={updatedLabel} size="small" color={color} />;
};

function StoreDetailsView({ highlightSearch }) {
  const validationSchema = Yup.object().shape({
    primary_name: Yup.string().required("Primary Contact Name is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Primary Email is required"),
    billing_name: Yup.string().required("Billing Contact Name is required"),
    billing_email: Yup.string()
      .email("Invalid email")
      .required("Billing Email is required"),
    pod_contact_name: Yup.string().required("POD Contact Name is required"),
    pod_email: Yup.string()
      .email("Invalid email")
      .required("POD Email is required"),
    // linked_payment_card: Yup.array().min(
    //   1,
    //   "At least one payment card is required"
    // ),
  });

  const validationSchemaForApiCreds = Yup.object().shape({
    clienId: Yup.string().required("Client ID is required"),
    client_secret: Yup.string().required("Client Secret is required"),
  });

  const validationSchemaForStoreDetails = Yup.object().shape({
    name: Yup.string().required("Store name is required"),
  });

  const { id } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const errorParam = searchParams.get("error");

    if (errorParam) {
      if(errorParam == 0){
        SuccessToast({
          message: "API Connection Renewed Successfully."
        })
      } else{
        const errorMessages = {
          "1": "We encountered an issue while trying to connect to your Walmart store. Please check your store settings and try again.",
          "2": "This Walmart partner ID is already in use. Please use a different ID.",
        }
        ErrorToast({
          message: errorMessages[errorParam]
        })
      }
    }
  }, [searchParams])

  const [initialValue, setInitialValue] = useState({
    primary_name: "",
    email: "",
    billing_name: "",
    billing_email: "",
    pod_contact_name: "",
    pod_email: "",
    linked_payment_card: [],
  });
  const { tableData: storeData } = useSelector(
    (state) => state.root.storeState.storeData,
  );
  const [initialValueForApiCreds, setInitialValueForApiCreds] = useState({
    clienId: "",
    client_secret: "",
  });
  const [initialValueForStoreDetails, setInitialValueForStoreDetail] = useState(
    {
      name: "",
    },
  );

  const [companyName, setCompanyName] = useState();
  const [apiStatus, setApiStatus] = useState({
    api_status: "",
    api_error: "",
  });
  const [isActive, setIsActive] = useState();
  const dispatch = useDispatch();
  const nevigate = useNavigate();
  const cardlist = useSelector((state) => state?.root?.paymetState?.cardlist);

  const walmartReconnectHandler = async function(e){
    const selectedRow = storeData?.find((item) => item.id === parseInt(id));

    let req = {
      apiData: {
        "name": selectedRow?.name,
      },
      resetState: resetStepperData,
      auth_type: 2,
      id,
    };
    const data = await dispatch(walmartAccount(req));
    if(data?.payload?.success){
      window.location.href = data?.payload?.redirect_url
    }
  }

  useEffect(() => {
    const selectedRow = storeData?.find((item) => item.id === parseInt(id));
    const values = {
      primary_name: selectedRow?.contact_name || "",
      email: selectedRow?.email || "",
      billing_name: selectedRow?.billing_contact_name || "",
      billing_email: selectedRow?.billing_email || "",
      pod_contact_name: selectedRow?.pod_contact_name || "",
      pod_email: selectedRow?.pod_email || "",
    };
    setInitialValue(values);
    setCompanyName(selectedRow?.name);
    setApiStatus({
      api_status: selectedRow?.api_status,
      api_error: selectedRow?.api_error,
    });
    setIsActive(selectedRow?.is_active ? "Active" : "InActive");
  }, [cardlist]);

  useEffect(() => {
    const selectedRow = storeData?.find((item) => item.id === parseInt(id));
    const values = {
      clienId: selectedRow?.walmart_client_id || "",
      client_secret: selectedRow?.walmart_client_secret || "",
    };
    // setInitialValueForApiCreds(values);
    setInitialValueForStoreDetail({ name: selectedRow?.name || "" });
  }, [cardlist?.name, id]);

  useEffect(() => {
    dispatch(listCardThunk());
  }, []);

  const headerData = [
    {
      linkName: "Setting",
      link: "/storedeatils",
      active: false,
    },
    {
      linkName: "Store Details",
      link: "/storedeatils",
      active: false,
    },
    {
      linkName: initialValueForStoreDetails?.name
        ? initialValueForStoreDetails?.name
        : "Unkown",
      link: id ? `/storedetailsview/${id}` : "/storedetailsview",
      active: true,
    },
  ];

  return (
    <>
      <HeaderNevigation headerData={headerData} />
      <Card>
        <CardContent>
          <Typography
            variant="headingXXXS"
            component="h4"
            sx={{ fontSize: "16px", letterSpacing: "auto" }}
          >
            {highlightSearch("Store Details")}
          </Typography>
          <Divider sx={{ pt: 2, mb: 3 }} />
          <Formik
            initialValues={initialValueForStoreDetails}
            validationSchema={validationSchemaForStoreDetails}
            enableReinitialize
            onSubmit={(values) => {
              try {
                const formdata = {
                  name: values.name,
                };

                if (id) {
                  dispatch(updateStoreData({ formdata, id }));
                } else {
                  dispatch(addStoreData({ formdata })).then((data) => {
                    if (data) {
                      nevigate("/storedeatils");
                    }
                  });
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              resetForm,
            }) => (
              <Form>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="bodyS" component="label">
                      {highlightSearch("Store Name")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextFieldComponent
                      id="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter store name"
                      error={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Divider sx={{ pt: 1 }} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ textAlign: "right", pt: 2 }}
                  >
                    <Button
                      variant="outlined"
                      sx={{ mr: 2 }}
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Discard
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Typography
            variant="headingXXXS"
            component="h4"
            sx={{ fontSize: "16px", letterSpacing: "auto" }}
          >
            {highlightSearch("Billing Information")}
          </Typography>
          <Divider sx={{ pt: 2, mb: 3 }} />
          <Formik
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              try {
                const formdata = {
                  contact_name: values.primary_name,
                  email: values.email,
                  billing_contact_name: values.billing_name,
                  billing_email: values.billing_email,
                  pod_contact_name: values.pod_contact_name,
                  pod_email: values.pod_email,
                };
                dispatch(
                  addDefaultCardData({
                    payment_method_id: values.linked_payment_card,
                  }),
                );

                if (id) {
                  dispatch(updateStoreData({ formdata, id }));
                } else {
                  dispatch(addStoreData({ formdata })).then((data) => {
                    if (data) {
                      nevigate("/storedeatils");
                    }
                  });
                }
              } catch (error) {
                console.log(error);
              }
            }}
            enableReinitialize
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              resetForm,
            }) => (
              <Form>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="bodyS" component="label">
                      {highlightSearch("Primary Contact Name")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextFieldComponent
                      id="primary_name"
                      name="primary_name"
                      placeholder="Enter primary contact name"
                      value={values.primary_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.primary_name && errors.primary_name}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="bodyS" component="label">
                      {highlightSearch("Primary Email")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextFieldComponent
                      id="email"
                      name="email"
                      placeholder="Enter primary email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && errors.email}
                    />
                  </Grid>
                  <>
                    <Grid item xs={6} sm={6} md={6}></Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={usePrimaryEmail}
                            onChange={(e) => {
                              setFieldValue("billing_email", values?.email);
                              setFieldValue(
                                "billing_name",
                                values?.primary_name,
                              );
                            }}
                            color="primary"
                            name="usePrimaryEmail"
                          />
                        }
                        label={
                          <span className="secondary-text-color font-tiny">
                            Use same email as my primary email
                          </span>
                        }
                      />
                    </Grid>
                  </>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="bodyS" component="label">
                      {highlightSearch("Billing Contact Name")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextFieldComponent
                      id="billing_name"
                      name="billing_name"
                      placeholder="Enter billing contact name"
                      value={values.billing_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.billing_name && errors.billing_name}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="bodyS" component="label">
                      {highlightSearch("Billing Email")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextFieldComponent
                      id="billing_email"
                      name="billing_email"
                      placeholder="Enter billing email"
                      value={values.billing_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.billing_email && errors.billing_email}
                    />
                  </Grid>
                  <>
                    <Grid item xs={6} sm={6} md={6}></Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={usePrimaryEmail}
                            onChange={(e) => {
                              setFieldValue(
                                "pod_contact_name",
                                values?.billing_name,
                              );
                              setFieldValue("pod_email", values?.billing_email);
                            }}
                            color="primary"
                            name="usePrimaryEmail"
                          />
                        }
                        label={
                          <span className="secondary-text-color font-tiny">
                            Use same email as my primary email
                          </span>
                        }
                      />
                    </Grid>
                  </>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="bodyS" component="label">
                      {highlightSearch("POD Contact Name")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextFieldComponent
                      id="pod_contact_name"
                      name="pod_contact_name"
                      placeholder="Enter POD contact name"
                      value={values?.pod_contact_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched?.pod_contact_name && errors?.pod_contact_name
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="bodyS" component="label">
                      {highlightSearch("POD Email")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextFieldComponent
                      id="pod_email"
                      name="pod_email"
                      placeholder="Enter POD email"
                      value={values?.pod_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched?.pod_email && errors?.pod_email}
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="bodyS" component="label">
                      {highlightSearch("Linked Payment Card")}
                    </Typography>
                  </Grid> */}
                  {/* <Grid item xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                      <Select
                        labelId="simple-select-label"
                        id="simple-select"
                        name="linked_payment_card"
                        value={values.linked_payment_card}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.linked_payment_card &&
                          errors.linked_payment_card
                        }
                      >
                        {cardlist?.map((item, i) => (
                          <MenuItem key={i} value={item.id}>
                            {item?.nickname}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={12} sm={12} md={12}>
                    <Divider sx={{ pt: 1 }} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ textAlign: "right", pt: 2 }}
                  >
                    <Button
                      variant="outlined"
                      sx={{ mr: 2 }}
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Discard
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Typography
            variant="headingXXXS"
            component="h4"
            sx={{ fontSize: "16px", letterSpacing: "auto" }}
          >
            {highlightSearch("API Credentials")}
          </Typography>
          <Divider sx={{ pt: 2, mb: 3 }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <CustomChip label={isActive} color="success" />
                  <Typography
                    variant="bodyS"
                    sx={{ fontWeight: 600, marginLeft: "8px" }}
                  >
                    {companyName ? companyName : "-"}
                  </Typography>
                </div>
                <div>
                  <ReconnectButton
                    size="large"
                    onClick={walmartReconnectHandler}
                  >
                    Renew API Connection
                  </ReconnectButton>
                </div>
              </Box>

              <div>
                {apiStatus?.api_status ? (
                  <ChipCard
                    label={apiStatus?.api_status}
                    title={apiStatus?.api_error}
                  />
                ) : (
                  <Chip label="None" color="default" size="small" />
                )}
                <Typography
                  variant="bodyS"
                  sx={{ fontWeight: 600, marginLeft: "8px" }}
                >
                  API Status
                </Typography>
              </div>
        </CardContent>
      </Card>
    </>
  );
}

export default withHighlight(StoreDetailsView);