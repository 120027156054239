import { createAsyncThunk } from "@reduxjs/toolkit";
import { authApi, authApiWithToken } from "../../../utils/authApi";
import { setLoading } from "../../slices/dataLoadingSlice";
import { SuccessToast, ErrorToast, InfoToast } from "../../../utils/Toaster";
import {
  resetAccStepper,
  resetAllStapperData,
  resetStepperData,
  setTimeStampForStore,
  walmartAccStepper,
} from "../../slices/auth/signupSlice";
import {
  setConfirmEmailDetail,
  setDashboardAccessible,
  setIsExternal,
  setLogin,
  setLoginDetail,
  setResetPasswordDetail,
} from "../../slices/auth/signinSlice";

// Register User
export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await authApi.post(
        "account/api-signup/",
        request?.apiData
      );
      console.log("Token", response);
      dispatch(setLoading(false));
      localStorage.setItem("token", response?.data?.tokens?.access);

      if (response?.data?.success === true) {
        SuccessToast(
          response?.data ? response?.data?.message : "Registration Successful"
        );
        dispatch(request?.resetState());
        dispatch(setDashboardAccessible(false));
        request?.navigate("/walmart");
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  }
);

// Login User
export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (request, { dispatch }) => {
    try {
      dispatch(setLoginDetail(request?.apiData));
      dispatch(setLoading(true));
      const response = await authApi.post(
        "account/api-login/",
        request?.apiData
      );
      dispatch(setLoading(false));
      if (response?.data?.success === true) {
        if (response?.data?.data?.user_detail?.is_external) {
          localStorage.setItem("token", response?.data?.data?.tokens?.access);
          if (response?.data?.data?.accounts?.data?.length > 0) {
            request?.fromUpdateAccount !== true &&
              SuccessToast(
                response?.data ? response?.data?.message : "Login Successful"
              );

            const responseForDate = await authApi.get(
              "account/is-created-account/",
              {
                headers: {
                  Authorization: `Bearer ${response?.data?.data?.tokens?.access}`, // Add the token in the Authorization header
                },
              }
            );

            const responseForCheckCaselog = await authApiWithToken.get(
              `api/case-log/?account_id=${response?.data?.data?.accounts?.data?.[0]?.id}&page=${1}&page_size=${10}`,
              {
                headers: {
                  Authorization: `Bearer ${response?.data?.data?.tokens?.access}`, // Replace your_token with the actual token variable
                },
              }
            );

            dispatch(setLogin(response?.data?.data));
            request?.navigate("/");
            dispatch(resetAllStapperData());
            dispatch(setDashboardAccessible(true));
          } else {
            request?.navigate("/walmart");
            dispatch(setDashboardAccessible(false));
          }
        } else {
          dispatch(
            setIsExternal(response?.data?.data?.user_detail?.is_external)
          );
          request?.navigate("/403");
        }
      } else if (response.data.data.is_external === false) {
        const csrfToken = response.data?.data?.csrf_token;
        const requestData = request?.apiData;

        function setCookie(name, value, days) {
          let expires = "";
          if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
          }
          document.cookie = name + "=" + (value || "") + expires + "; path=/";
        }

        setCookie("csrfToken", csrfToken, 7);

        //   function getCookie(name) {
        //     const nameEQ = name + "=";
        //     const ca = document.cookie.split(";");
        //     for (let i = 0; i < ca.length; i++) {
        //       let c = ca[i];
        //       while (c.charAt(0) === " ") c = c.substring(1, c.length);
        //       if (c.indexOf(nameEQ) === 0)
        //         return c.substring(nameEQ.length, c.length);
        //     }
        //     return null;
        //   }

        //  getCookie("csrfToken");

        // Create a new FormData object
        // const formData = new FormData();

        // // Append each key-value pair to the FormData object
        // formData.append("email", requestData.email);
        // formData.append("password", requestData.password);

        // const responseForLogin = await axios.post(
        //   "http://192.168.1.134:8000/account/login/",
        //   JSON.stringify({email : requestData.email ,password : requestData.password }),
        //   {
        //     headers: {
        //       "Content-Type": "multipart/form-data", // For form data
        //       "X-CSRFToken": myCookieValue, // CSRF token from cookies
        //     },
        //     withCredentials: true, // Include cookies in the request
        //   }
        // )

        window.location.href = "http://192.168.1.134:8000/api/dashboard";
        // console.log("LoginResponce", responseForLogin);
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  }
);

export const storeUserDetail = createAsyncThunk(
  "user/storeUserDetail",
  async (request, { dispatch }) => {
    try {
      dispatch(setLoginDetail(request?.apiData));
      dispatch(setLoading(true));
      const response = await authApi.post(
        "account/api-login/",
        request?.apiData
      );
      dispatch(setLoading(false));
      localStorage.setItem("token", response?.data?.data?.tokens?.access);
      if (response?.data?.success === true) {
        const responseForDate = await authApi.get(
          "account/is-created-account/",
          {
            headers: {
              Authorization: `Bearer ${response?.data?.data?.tokens?.access}`, // Add the token in the Authorization header
            },
          }
        );

        const responseForCheckCaselog = await authApiWithToken.get(
          `api/case-log/?account_id=${response?.data?.data?.accounts?.data?.[0]?.id}&page=${1}&page_size=${10}`,
          {
            headers: {
              Authorization: `Bearer ${response?.data?.data?.tokens?.access}`, // Replace your_token with the actual token variable
            },
          }
        );

        dispatch(setLogin(response?.data?.data));
        if (response?.data?.data?.accounts?.data?.length > 0) {
          request?.navigate("/");
          dispatch(resetAllStapperData());
          dispatch(setDashboardAccessible(true));
        } else {
          request?.navigate("/walmart");
          dispatch(setDashboardAccessible(false));
        }
      } else if (response.data.data.is_external === false) {
        const csrfToken = response.data?.data?.csrf_token;
        const requestData = request?.apiData;

        function setCookie(name, value, days) {
          let expires = "";
          if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
          }
          document.cookie = name + "=" + (value || "") + expires + "; path=/";
        }

        setCookie("csrfToken", csrfToken, 7);

        //   function getCookie(name) {
        //     const nameEQ = name + "=";
        //     const ca = document.cookie.split(";");
        //     for (let i = 0; i < ca.length; i++) {
        //       let c = ca[i];
        //       while (c.charAt(0) === " ") c = c.substring(1, c.length);
        //       if (c.indexOf(nameEQ) === 0)
        //         return c.substring(nameEQ.length, c.length);
        //     }
        //     return null;
        //   }

        //  getCookie("csrfToken");

        // Create a new FormData object
        // const formData = new FormData();

        // // Append each key-value pair to the FormData object
        // formData.append("email", requestData.email);
        // formData.append("password", requestData.password);

        // const responseForLogin = await axios.post(
        //   "http://192.168.1.134:8000/account/login/",
        //   JSON.stringify({email : requestData.email ,password : requestData.password }),
        //   {
        //     headers: {
        //       "Content-Type": "multipart/form-data", // For form data
        //       "X-CSRFToken": myCookieValue, // CSRF token from cookies
        //     },
        //     withCredentials: true, // Include cookies in the request
        //   }
        // )

        window.location.href = "http://192.168.1.134:8000/api/dashboard";
        // console.log("LoginResponce", responseForLogin);
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  }
);

// get time stamp
export const getTimeStampForStore = createAsyncThunk(
  "get/timestmap/store",
  async (request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await authApiWithToken.get(
        "account/is-created-account/"
      );
      if (response?.data?.success === true) {
        dispatch(setTimeStampForStore(response.data?.data?.created_at || ""));
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }
);

// Stepper
export const walmartAccount = createAsyncThunk(
  "user/walmartAccount",
  async (request, { dispatch }) => {
    try {
      let requestBody = {
        ...request?.apiData,
        auth_type: request?.auth_type
      }
      if(request.id){
        requestBody.id = request.id
      }
      dispatch(setLoading(true));
      const response = await authApiWithToken.post(
        "/account/api/initiate_walmart_oauth/",
        requestBody
      );
      dispatch(setLoading(false));
      if (response?.data?.success === true) {
        InfoToast(
          response?.data
          ? response?.data?.message
          : "Account Created Successful"
        );
        return response?.data
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.log("error: ", error);
      ErrorToast({
        message: error?.response?.data?.errors
          ? error?.response?.data?.errors
          : error,
      });
    }
  }
);

// Stepper
export const walmartAccountCreate = createAsyncThunk(
  "user/walmartAccount",
  async (request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await authApiWithToken.post(
        "account/store/create/",
        request?.apiData
      );
      dispatch(setLoading(false));

      if (response?.data?.success === true) {
        dispatch(walmartAccStepper(response?.data?.data));
        dispatch(request?.resetState());
        SuccessToast(
          response?.data
            ? response?.data?.message
            : "Account Created Successful"
        );
      }
    } catch (error) {
      dispatch(setLoading(false));
      ErrorToast({ message: error?.response?.data?.errors });
      console.log("error: ", error);
    }
  }
);

// Confirm Email for reset password
export const confirmEmailAddress = createAsyncThunk(
  "user/confirmEmailAddress",
  async (request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await authApi.post(
        "account/api/password-reset/",
        request?.apiData
      );
      dispatch(setLoading(false));

      if (response?.data?.success === true) {
        dispatch(setConfirmEmailDetail(response?.data?.data));
        SuccessToast(
          response?.data ? response?.data?.message : "Password reset link sent."
        );
        request?.navigate("/login");
      }
    } catch (error) {
      dispatch(setLoading(false));
      ErrorToast({ message: error?.response?.data?.errors });
      console.log("error: ", error);
    }
  }
);

// Stepper
export const resetPasswordHandle = createAsyncThunk(
  "user/resetPasswordHandle",
  async (request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await authApi.post(
        "account/api/password-reset-confirm/",
        request?.apiData
      );
      dispatch(setLoading(false));

      if (response?.data?.success === true) {
        dispatch(setResetPasswordDetail(response?.data?.data));
        SuccessToast(
          response?.data
            ? response?.data?.message
            : "Password reset Successfully"
        );
        request?.navigate("/login");
      }
    } catch (error) {
      dispatch(setLoading(false));
      ErrorToast({ message: error?.response?.data?.errors });
      console.log("error: ", error);
    }
  }
);