import payment from "../assets/images/icon/sidebar/payment.svg";
import dashboard from "../assets/images/icon/sidebar/dashboard.svg";
import profile from "../assets/images/icon/sidebar/profile.svg";
import store from "../assets/images/icon/sidebar/store.svg";
import helpdesk from "../assets/images/icon/sidebar/helpdesk.svg";

export const homeMenu = [
  {
    icon: dashboard,
    label: "Dashboard",
    link: "/",
  },
];

export const settingMenues = [
  {
    icon: profile,
    label: "Profile and Account",
    link: "/profileandacount",
  },
  {
    icon: store,
    label: "Store Details",
    link: "/storedeatils",
  },
  // {
  //   icon: payment,
  //   label: "Payment Method",
  //   link: "/paymentmethod",
  // },
];

export const resourcesMenu = [
  {
    icon: helpdesk,
    label: "Help Center",
    // link: "http://support.refundstacker.com/",
  },
];
