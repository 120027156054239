import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const WalmartIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 40 40" viewBox="0 0 40 40" id="walmart">
        <switch>
            <g>
            <path fill="#ffb819" d="M22.3,34.4c0.1,0.9-0.6,1.6-1.5,1.6h-1.7c-0.9,0-1.6-0.8-1.5-1.6l0.8-8.2
                    c0.1-0.7,0.6-1.1,1.2-1.1h0.7c0.6,0,1.2,0.5,1.2,1.1L22.3,34.4z M33.6,25.1l-7.5-3.5c-0.6-0.3-1.3,0-1.6,0.5l-0.4,0.6
                    c-0.3,0.6-0.2,1.3,0.4,1.6l6.7,4.8c0.7,0.5,1.7,0.3,2.2-0.5l0.9-1.5C34.7,26.5,34.4,25.5,33.6,25.1z M34.3,12.7l-0.9-1.5
                    c-0.4-0.8-1.4-1-2.2-0.5l-6.7,4.8c-0.5,0.4-0.7,1.1-0.4,1.7l0.4,0.6c0.3,0.6,1,0.8,1.6,0.5l7.5-3.5C34.4,14.5,34.7,13.5,34.3,12.7
                    z M20.9,4h-1.7c-0.9,0-1.6,0.8-1.5,1.6l0.8,8.2c0.1,0.7,0.6,1.1,1.2,1.1h0.7c0.6,0,1.2-0.5,1.2-1.1l0.8-8.2
                    C22.4,4.8,21.7,4,20.9,4z M15.5,15.5l-6.7-4.8C8,10.3,7,10.5,6.6,11.3l-0.9,1.5c-0.4,0.8-0.1,1.7,0.7,2.1l7.5,3.5
                    c0.6,0.3,1.3,0,1.6-0.5l0.4-0.6C16.2,16.6,16,15.9,15.5,15.5z M15.9,22.8l-0.4-0.6c-0.3-0.6-1-0.8-1.6-0.5l-7.5,3.5
                    c-0.8,0.4-1.1,1.3-0.7,2.1l0.9,1.5c0.4,0.8,1.4,1,2.2,0.5l6.7-4.8C16,24.1,16.2,23.4,15.9,22.8z"></path>
            </g>
        </switch>
      </svg>
    </SvgIcon>
  );
};

export default WalmartIcon;