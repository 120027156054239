import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useSearchParams } from "react-router-dom";

function UnAuthorized() {
  // Retrieve the token from localStorage
  const token = localStorage.getItem("token");
  const { isDashboardAccessible } = useSelector(
    (state) => state.root?.login,
  );

  const [searchParams] = useSearchParams()

  // Conditionally render based on the presence of the token
  return !token || !isDashboardAccessible ? 
  <Outlet /> 
  : <Navigate to={`/${searchParams.toString() ? `?${searchParams.toString()}` : ""}`} />;
}

export default UnAuthorized;
