import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import button_view from "../../../assets/dashboard_icone/button_view.svg";
import Aja from "../../../assets/dashboard_icone/Aja.svg";
import { Link, useParams } from "react-router-dom";
import TextFieldComponent from "../../../components/TextFieldComponent";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import {
  addCaseLogComments,
  getCaseLogDetails,
} from "../../../redux/thunk/dashboard/dashboardThunk";
import dayjs from "dayjs";

function TableView() {
  const columns = [
    {
      label: "Return Order Number",
      name: "return_order_number",
      options: { filter: false, sort: false },
    },
    {
      label: "Order Number",
      name: "order_number",
      options: { filter: false, sort: false },
    },
    {
      label: "P.O Number",
      name: "po_number",
      options: { filter: false, sort: false },
    },
    {
      label: "Status",
      name: "status",
      options: { filter: false, sort: false },
    },
    {
      label: "Refund Status",
      name: "current_refund_status",
      options: { filter: false, sort: false },
    },
    {
      label: "Return Reason",
      name: "return_reason",
      options: { filter: false, sort: false },
    },
    {
      label: "Shipment ID",
      name: "shipment_id",
      options: { filter: false, sort: false },
    },
    {
      label: "Date Filed",
      name: "date_filed",
      options: { filter: false, sort: false },
    },
  ];

  const options = {
    search: false,
    filter: false,
    responsive: "standard",
    filterType: "dropdown",
    selectableRows: "none",
    print: false,
    viewColumns: false,
    download: false,
    pagination: false,
  };

  const dispatch = useDispatch();
  const { id } = useParams();
  const { walmartReturnsTable, caseDetails } = useSelector(
    (state) => state.root?.dashBoardState?.caseLogData,
  );
  const [tableData, setTableData] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    dispatch(getCaseLogDetails({ id }));
  }, []);

  useEffect(() => {
    const walmartReturns = walmartReturnsTable?.map((item) => {
      return {
        ...item,
        shipment_id: item?.shipment_id ? item?.shipment_id : "-",
        date_filed: item?.date_filed ? item?.date_filed : "-",
      };
    });
    setTableData(walmartReturns);
  }, [walmartReturnsTable]);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      addCaseLogComments({ comment: message, dispute_case_id: parseInt(id) }),
    );
    setMessage("");
  };

  return (
    <>
      {/* First Row */}
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            sx={{ fontWeight: "600", fontSize: "16px", color: "#101010" }}
          >
            Walmart Dispute Case
          </Typography>
          <AiFillEdit size={16} />
        </Grid>
        <Grid>
          <Button
            startIcon={<img src={button_view} />} // This adds the icon before the text
            variant="contained"
            className="secondary-button"
          >
            View Email Tempate
          </Button>
        </Grid>
      </Grid>

      {/* Second Row */}
      <Grid container columnGap={3} xs={12} mt={2.5} sx={{ display: "flex" }}>
        <Grid
          item
          xs={5.85}
          sx={{
            height: "572px",
            background: "white",
            borderRadius: "6px",
            padding: "16px",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              borderBottom: "1px solid #EFF2F5",
              paddingBottom: 1.5,
            }}
          >
            <img src={Aja} />
            <Typography
              sx={{ fontWeight: 600, fontSize: "16px", color: "#101010" }}
            >
              {caseDetails?.account_name}
            </Typography>
          </Grid>

          <Grid mt={1.5} xs={12} sx={{ display: "flex", gap: "6px" }}>
            <Grid xs={4} sx={{ textAlign: "start" }}>
              <Typography
                sx={{ fontWeight: 400, fontSize: "14px", color: "#474747" }}
              >
                Created Date
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: "16px", color: "#101010" }}
              >
                {dayjs(caseDetails?.created_at).format("MMM DD,YYYY")}
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: "16px", color: "#101010" }}
              >
                {dayjs(caseDetails?.created_at).format("h:mm A")}
              </Typography>
            </Grid>
            <Grid xs={4} sx={{ textAlign: "start" }}>
              <Typography
                sx={{ fontWeight: 400, fontSize: "14px", color: "#474747" }}
              >
                Updated Date
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: "16px", color: "#101010" }}
              >
                {dayjs(caseDetails?.updated_at).format("MMM DD,YYYY")}
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: "16px", color: "#101010" }}
              >
                {dayjs(caseDetails?.updated_at).format("h:mm A")}
              </Typography>
            </Grid>
            <Grid xs={4} sx={{ textAlign: "start" }}>
              <Typography
                sx={{ fontWeight: 400, fontSize: "14px", color: "#474747" }}
              >
                Case ID
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: "16px", color: "#101010" }}
              >
                {caseDetails?.id}
              </Typography>
            </Grid>
          </Grid>

          <Grid mt={3} xs={12} sx={{ display: "flex", gap: "6px" }}>
            <Grid xs={4} sx={{ textAlign: "start" }}>
              <Typography
                sx={{ fontWeight: 400, fontSize: "14px", color: "#474747" }}
              >
                Case Opened Date
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: "16px", color: "#101010" }}
              >
                {caseDetails?.walmart_case_created_date !== null
                  ? dayjs(caseDetails?.walmart_case_created_date).format(
                      "MMM DD,YYYY",
                    )
                  : "None"}
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: "16px", color: "#101010" }}
              >
                {caseDetails?.walmart_case_created_date !== null &&
                  dayjs(caseDetails?.walmart_case_created_date).format(
                    "h:mm A",
                  )}
              </Typography>
            </Grid>
            <Grid xs={4} sx={{ textAlign: "start" }}>
              <Typography
                sx={{ fontWeight: 400, fontSize: "14px", color: "#474747" }}
              >
                Walmart Case Number
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: "16px", color: "#101010" }}
              >
                {caseDetails?.walmart_case_number}
              </Typography>
            </Grid>
            <Grid xs={4} sx={{ textAlign: "start" }}>
              <Typography
                sx={{ fontWeight: 400, fontSize: "14px", color: "#474747" }}
              >
                Dispute Deadline
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: "16px", color: "#101010" }}
              >
                {dayjs(caseDetails?.dispute_deadline).format("MMM DD,YYYY")}
              </Typography>
            </Grid>
          </Grid>

          <Grid sx={{ textAlign: "start" }}>
            <Typography
              mt={5}
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: "#474747",
              }}
            >
              Return Dispute Rule
            </Typography>
            <Link to={""}>None-Received Return Shipping Fee</Link>
          </Grid>
        </Grid>

        <Grid
          item
          xs={5.85}
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "24px",
            height: "572px",
          }}
        >
          <Grid
            sx={{
              height: "148px",
              background: "white",
              borderRadius: "6px",
              padding: "16px",
            }}
          >
            <Grid
              sx={{
                borderBottom: "1px solid #EFF2F5",
                paddingBottom: 1.5,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "#101010",
                  textAlign: "start",
                }}
              >
                Quick Facts
              </Typography>
            </Grid>

            <Grid mt={1.5} xs={12} sx={{ display: "flex", gap: "8px" }}>
              <Grid xs={2.4} sx={{ textAlign: "start" }}>
                <Typography
                  sx={{ fontWeight: 500, fontSize: "14px", color: "#474747" }}
                >
                  RETURNS DISPUTED
                </Typography>
                <Typography
                  mt={0.5}
                  sx={{ fontWeight: 600, fontSize: "16px", color: "#101010" }}
                >
                  {caseDetails?.return_disputed}
                </Typography>
              </Grid>
              <Grid xs={2.4} sx={{ textAlign: "start" }}>
                <Typography
                  sx={{ fontWeight: 500, fontSize: "14px", color: "#474747" }}
                >
                  RETURNS WON
                </Typography>
                <Typography
                  mt={0.5}
                  sx={{ fontWeight: 600, fontSize: "16px", color: "#101010" }}
                >
                  -
                </Typography>
              </Grid>
              <Grid xs={2.4} sx={{ textAlign: "start" }}>
                <Typography
                  sx={{ fontWeight: 500, fontSize: "14px", color: "#474747" }}
                >
                  AMOUNT DISPUTED
                </Typography>
                <Typography
                  mt={0.5}
                  sx={{ fontWeight: 600, fontSize: "16px", color: "#101010" }}
                >
                  ${caseDetails?.dispute_amount}
                </Typography>
              </Grid>
              <Grid xs={2.4} sx={{ textAlign: "start" }}>
                <Typography
                  sx={{ fontWeight: 500, fontSize: "14px", color: "#474747" }}
                >
                  AMOUNT PENDING
                </Typography>
                <Typography
                  mt={0.5}
                  sx={{ fontWeight: 600, fontSize: "16px", color: "#101010" }}
                >
                  ${caseDetails?.amount_pending}
                </Typography>
              </Grid>
              <Grid xs={2.4} sx={{ textAlign: "start" }}>
                <Typography
                  sx={{ fontWeight: 500, fontSize: "14px", color: "#474747" }}
                >
                  AMOUNT REFUNDED
                </Typography>
                <Typography
                  mt={0.5}
                  sx={{ fontWeight: 600, fontSize: "16px", color: "#101010" }}
                >
                  ${caseDetails?.amount_refunded}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            sx={{
              position: "relative",
              height: "400px",
              background: "white",
              borderRadius: "6px",
              padding: "16px",
            }}
          >
            <Grid
              sx={{
                borderBottom: "1px solid #EFF2F5",
                paddingBottom: 1.5,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "#101010",
                  textAlign: "start",
                }}
              >
                Comments
              </Typography>
            </Grid>

            <Grid item mt={2}>
              <TextFieldComponent
                id="comments"
                name="comments"
                //   label=""
                multiline
                placeholder="Enter comments here"
                rows={9}
                value={message}
                onChange={handleInputChange}
                // error={errors?.message}
              />
            </Grid>

            <div
              style={{ borderBottom: "1px solid #EFF2F5", marginTop: "24px" }}
            ></div>

            <Button
              sx={{
                position: "absolute",
                bottom: "16px",
                right: "16px",
                background: "#003399 !important",
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ mt: 2.5 }}>
        <MUIDataTable
          className="mui-data-table"
          title={
            <Typography sx={{ fontWeight: "600", p: 0 }} textAlign={"start"}>
              Associated Returns
            </Typography>
          }
          data={tableData}
          columns={columns}
          options={options}
          sx={{
            "& .MuiTablePagination-root": {
              display: "none",
            },
          }}
        />
      </Grid>
    </>
  );
}

export default TableView;
