import React, { useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
  useMediaQuery,
  IconButton,
  Tooltip,
} from "@mui/material";
import sidebarLogo from "../../assets/images/logo/logo.svg";
import sidebarLogoIcon from "../../assets/images/logo/logoIcon.svg";

// import DashboardIcon from '../../assets/images/icon/sidebar/dashboard.svg';
// import ProfileIcon from '../../assets/images/icon/sidebar/profile.svg';
// import StoreIcon from '../../assets/images/icon/sidebar/store.svg';
// import PaymentIcon from '../../assets/images/icon/sidebar/payment.svg';
import { useNavigate } from "react-router-dom";
import {
  homeMenu,
  settingMenues,
  resourcesMenu,
} from "../../menu-items/menues";
import MenuIcon from "@mui/icons-material/Menu";
import { sideBarToggle } from "../../redux/slices/sidebarSlice";
import { useDispatch, useSelector } from "react-redux";

const drawerWidth = 240;
const closedDrawerWidth = 56;

function Sidebar({ open }) {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { mobileToggle } = useSelector(
    (state) => state.root.sidebatToggleState
  );
  const dispatch = useDispatch();

  const handleMobileDrawerToggle = () => {
    dispatch(sideBarToggle(mobileToggle ? false : true));
  };

  return (
    <>
      {isSmallScreen ? (
        <IconButton
          onClick={handleMobileDrawerToggle}
          className="mobileDrawerToggle"
        >
          {open ? <MenuIcon /> : <MenuIcon />}
        </IconButton>
      ) : null}
      {isSmallScreen ? (
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileToggle}
          onClose={handleMobileDrawerToggle}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box sx={{ p: 2 }}>
            <Box mb={3} sx={{ textAlign: "center" }}>
              <img
                src={open ? sidebarLogo : sidebarLogoIcon}
                className={open ? "largeLogo" : "smallLogo"}
                style={{ maxWidth: open ? "175px" : "23px" }}
                alt="Logo"
              />
            </Box>
            <List disablePadding>
              {open && (
                <Typography variant="bodyXS" component="div">
                  Home
                </Typography>
              )}

              {homeMenu.map((item, index) => (
                <ListItem key={index}>
                  <ListItemButton
                    onClick={() => {
                      navigate(item.link);
                      handleMobileDrawerToggle();
                    }}
                  >
                    <Tooltip
                      title={!open ? item.label : ""}
                      placement="right-start"
                    >
                      <ListItemIcon>
                        <img src={item.icon} alt="Dashboard" />
                      </ListItemIcon>
                    </Tooltip>
                    {open && <ListItemText primary={item.label} />}
                  </ListItemButton>
                </ListItem>
              ))}

              {open && (
                <Typography variant="bodyXS" component="div">
                  settings
                </Typography>
              )}
              {settingMenues.map((item, index) => (
                <ListItem key={index}>
                  <ListItemButton
                    onClick={() => {
                      navigate(item.link);
                      handleMobileDrawerToggle();
                    }}
                  >
                    <Tooltip
                      title={!open ? item.label : ""}
                      placement="right-start"
                    >
                      <ListItemIcon>
                        <img src={item.icon} alt="Dashboard" />
                      </ListItemIcon>
                    </Tooltip>
                    {open && <ListItemText primary={item.label} />}
                  </ListItemButton>
                </ListItem>
              ))}

              {/* Resources Menu */}
              {open && (
                <Typography variant="bodyXS" component="div">
                  Resources
                </Typography>
              )}
              {resourcesMenu.map((item, index) => (
                <ListItem key={index}>
                  <ListItemButton
                    onClick={() => {
                      if (item.label === "Help Center") {
                        window.open(
                          "http://support.refundstacker.com/",
                          "_blank"
                        );
                      } else {
                        navigate(item.link);
                      }
                    }}
                  >
                    <Tooltip
                      title={!open ? item.label : ""}
                      placement="right-start"
                    >
                      <ListItemIcon>
                        <img src={item.icon} alt={item.label} />
                      </ListItemIcon>
                    </Tooltip>
                    {open && <ListItemText primary={item.label} />}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            width: open ? drawerWidth : closedDrawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: open ? drawerWidth : closedDrawerWidth,
              padding: open ? "24px 12px" : "24px 6px",
            },
          }}
          open={open}
        >
          <Box mb={3} sx={{ textAlign: "center" }}>
            <img
              src={open ? sidebarLogo : sidebarLogoIcon}
              className={open ? "largeLogo" : "smallLogo"}
              style={{ maxWidth: open ? "175px" : "23px" }}
              alt="Logo"
            />
          </Box>
          <List disablePadding>
            {open && (
              <Typography variant="bodyXS" component="div">
                Home
              </Typography>
            )}

            {homeMenu.map((item, index) => (
              <ListItem key={index}>
                <ListItemButton
                  onClick={() => {
                    navigate(item.link);
                    handleMobileDrawerToggle();
                  }}
                >
                  <Tooltip
                    title={!open ? item.label : ""}
                    placement="right-start"
                  >
                    <ListItemIcon>
                      <img src={item.icon} alt="Dashboard" />
                    </ListItemIcon>
                  </Tooltip>
                  {open && <ListItemText primary={item.label} />}
                </ListItemButton>
              </ListItem>
            ))}

            {open && (
              <Typography variant="bodyXS" component="div">
                settings
              </Typography>
            )}
            {settingMenues.map((item, index) => (
              <ListItem key={index}>
                <ListItemButton onClick={() => navigate(item.link)}>
                  <Tooltip
                    title={!open ? item.label : ""}
                    placement="right-start"
                  >
                    <ListItemIcon>
                      <img src={item.icon} alt="Dashboard" />
                    </ListItemIcon>
                  </Tooltip>
                  {open && <ListItemText primary={item.label} />}
                </ListItemButton>
              </ListItem>
            ))}

            {/* Resources Menu */}
            {open && (
              <Typography variant="bodyXS" component="div">
                Resources
              </Typography>
            )}
            {resourcesMenu.map((item, index) => (
              <ListItem key={index}>
                <ListItemButton
                  onClick={() => {
                    if (item.label === "Help Center") {
                      window.open(
                        "http://support.refundstacker.com/",
                        "_blank"
                      );
                    } else {
                      navigate(item.link);
                    }
                  }}
                >
                  <Tooltip
                    title={!open ? item.label : ""}
                    placement="right-start"
                  >
                    <ListItemIcon>
                      <img src={item.icon} alt={item.label} />
                    </ListItemIcon>
                  </Tooltip>
                  {open && <ListItemText primary={item.label} />}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      )}
    </>
  );
}

export default Sidebar;
