import axios from "axios";
import { setLoading } from "../redux/slices/dataLoadingSlice";
import { store } from "../store"; // Import your Redux store
import { ErrorToast } from "./Toaster";

export const authApi = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
  headers: {
    "Content-Type": "application/json",
  },
});

export const authApiWithToken = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
  headers: {
    "Content-Type": "application/json",
  },
});

authApi.interceptors.request.use(
  (request) => {
    // Show loader
    store.dispatch(setLoading(true));

    return request;
  },
  (error) => {
    // Hide loader in case of a request error
    store.dispatch(setLoading(false));
    return Promise.reject(error);
  },
);

// Response interceptor to hide loader
authApi.interceptors.response.use(
  (response) => {
    // Hide loader
    store.dispatch(setLoading(false));
    return response;
  },
  (error) => {
    ErrorToast(error.response.data.errors);
    // Hide loader in case of a response error
    store.dispatch(setLoading(false));
    return Promise.reject(error);
  },
);

authApiWithToken.interceptors.request.use(
  (request) => {
    const accessToken = localStorage.getItem("token") || null;
    if (accessToken) {
      request.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    // check if we should skip the loader
    const skipLoader = request.headers["x-skip-loading"] === "true";
    
    if (!skipLoader) {
      store.dispatch(setLoading(false));
    }

    // Show loader
    // store.dispatch(setLoading(true));

    return request;
  },
  (error) => {
    // Hide loader in case of a request error
    store.dispatch(setLoading(false));
    return Promise.reject(error);
  },
);

// Response interceptor to hide loader
authApiWithToken.interceptors.response.use(
  (response) => {
    // Hide loader
    store.dispatch(setLoading(false));
    return response;
  },
  (error) => {
    // Hide loader in case of a response error
    store.dispatch(setLoading(false));
    return Promise.reject(error);
  },
);

export default authApiWithToken;
